body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Monofett', Handwriting;
   /* 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  max-width: 100% !important;
  overflow-x: hidden !important;
  /* background-color: #022911 !important; */
  /* background-image: url(/public/bg.png); */
  background-size:100%;
  background-repeat:repeat-y;
  background-position-x: center;
}

code {
  font-family:"Monofett", source-code-pro, Monaco, Consolas, handwriting, monospace,;
}

.player-wrapper {
  position: fixed;
  min-height: 101vh !important;
  min-width: 100vw !important;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  top: 0px;
  left:0;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .react-player {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
  .destopText {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .react-player {
    position: fixed;
    top: 0;
    left: 0vw;
    z-index: -1;
  }
  .destopText {
    margin-top: 20vh !important;
    position: relative;
  }
  .homeMobile {
    position: relative;
    top: -20vh;
    width: 100%;
    height: 101vh !important;
    z-index: -2;
  }
}

.bgHome {
  background-color: rgba(0, 0, 0, 0.7);
}


.iframe{
  width: 100vw !important;
}

.fixed{
  position: fixed !important;
}

.homeText{
  color: white;
  font-size: larger;
  
}
.samllText{
  font-size: smaller;
}
.picBG{
  box-shadow: 55px 55px 1px;
color: #022911;
margin-bottom: 55px;
}

.navbarbg{
  background-color: rgba(39, 55, 70, 0.009);
}

.fireworks{
  z-index: -4 !important;
}

.blankspace{
  min-height: 15vh !important;
  vertical-align: middle !important;
}

.a5{
  height: 400 !important;
  width: 260 !important;
  align-items: center;
}

.margin{
  height: 200 !important;
}

.winietki{
  height:59,4mm !important;
}